import { requestsFactory } from 'redux-requests-factory';

import axiosInstance from './axios';

const getPaymentSystems = () => axiosInstance.get('/payment_systems');

export const PAYMENT_SYSTEM_STATUS = {
  NOT_SYNCED: 'non_synced',
};

export const {
  loadDataAction: loadPaymentSystemsAction,
  forcedLoadDataAction: forceLoadPaymentSystemsAction,
  responseSelector: paymentsSystemsResponseSelector,
  setResponseAction: setPaymentSystemResponse,
} = requestsFactory({
  request: getPaymentSystems,
  stateRequestKey: 'payment-systems',
  transformResponse: (response) => response || [],
});

const savePaymentSystem = (paymentSystem) => axiosInstance.post('/payment_system_batch', paymentSystem);

export const {
  doRequestAction: savePaymentSystemAction,
  requestFulfilledAction: savePaymentSystemFulfilledAction,
  requestRejectedAction: savePaymentSystemRejectedAction,
  errorSelector: savePaymentSystemErrorSelector,
} = requestsFactory({
  request: savePaymentSystem,
  stateRequestKey: 'payment-system__post',
  fulfilledActions: [() => forceLoadPaymentSystemsAction()],
});

const resyncPaymentSystem = (paymentSystemId) => axiosInstance.post(`/payment_systems/${paymentSystemId}/resync`);

export const {
  doRequestAction: resyncPaymentSystemAction,
  requestFulfilledAction: resyncPaymentSystemFulfilledAction,
  requestRejectedAction: resyncPaymentSystemRejectedAction,
} = requestsFactory({
  request: resyncPaymentSystem,
  stateRequestKey: 'payment-system-resync',
});
