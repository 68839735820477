import React from 'react';
import ThemeProvider from '@mui/material/styles/ThemeProvider';
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom';
import { NotificationContainer } from 'react-notifications';
import Cashflows from 'pages/Cashflows';
import PaymentSystems from 'pages/PaymentSystems';
import Snapshots from 'pages/Snapshots';

import 'react-notifications/lib/notifications.css';
import theme from './theme/index';
import Header from './components/molecules/Header';
import { StyledContainer } from './App.styles';

const App = () => (
  <ThemeProvider theme={theme}>
    <NotificationContainer />

    <Router>
      <Header />
      <StyledContainer maxWidth="lg">
        <Switch>
          <Route path="/payment_systems">
            <PaymentSystems />
          </Route>
          <Route path="/cashflows">
            <Cashflows />
          </Route>
          <Route path="/snapshots">
            <Snapshots />
          </Route>
          <Redirect to="/payment_systems" />
        </Switch>
      </StyledContainer>
    </Router>
  </ThemeProvider>
);

export default App;
