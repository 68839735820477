import React from 'react';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';

import CasinoSnapshotsForm from './sub-components/CasinoSnapshotsForm';
import SnapshotForm from './sub-components/SnapshotForm';

const Snapshot = () => {
  return (
    <>
      <Typography variant="h4" align="center" gutterBottom>
        New Snapshot
      </Typography>
      <Grid container spacing={6}>
        <Grid item sm={6}>
          <SnapshotForm />
        </Grid>
        <Grid item sm={6}>
          <CasinoSnapshotsForm />
        </Grid>
      </Grid>
    </>
  );
};

export default Snapshot;
