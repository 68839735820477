import React, { useState, useCallback, useEffect, useMemo } from 'react';
import IconButton from '@mui/material/IconButton';
import Refresh from '@mui/icons-material/Refresh';
import ErrorIcon from '@mui/icons-material/Error';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Popover from '@mui/material/Popover';
import Box from '@mui/material/Box';
import StyledBox from 'components/atoms/Box';
import DataGrid from 'components/molecules/DataGrid';
import { useDispatch, useSelector } from 'react-redux';
import {
  loadPaymentSystemsAction,
  forceLoadPaymentSystemsAction,
  resyncPaymentSystemAction,
  PAYMENT_SYSTEM_STATUS,
} from 'api/payment-systems';
import StyledLink from 'components/atoms/Link';
import AddButton from 'components/atoms/AddButton';
import { paymentSystemsSelector } from 'store/payment-system/selectors';

const ErrorHint = ({ children, content }) => {
  const [anchor, setAnchor] = useState(null);

  const onClick = (event) => {
    setAnchor(anchor ? null : event.target);
  };
  const onClose = () => setAnchor(null);

  return (
    <>
      <Typography>
        {children}{' '}
        <IconButton onClick={onClick}>
          <ErrorIcon />
        </IconButton>
      </Typography>
      <Popover
        open={Boolean(anchor)}
        anchorEl={anchor}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        onClose={onClose}
        disableRestoreFocus
      >
        {content}
      </Popover>
    </>
  );
};

const PaymentMethods = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(loadPaymentSystemsAction());
  }, [dispatch]);

  const paymentSystems = useSelector(paymentSystemsSelector);
  const onRefreshClick = useCallback(() => dispatch(forceLoadPaymentSystemsAction()), [dispatch]);
  const onResyncClick = useCallback(
    (id) => () => {
      dispatch(resyncPaymentSystemAction(id));
    },
    [dispatch],
  );

  const columns = useMemo(
    () => [
      { field: 'name', headerName: 'Name', flex: 1, minWidth: 200 },
      {
        field: 'provider',
        headerName: 'Provider',
        minWidth: 150,
      },
      {
        field: 'casino',
        headerName: 'Casino',
        minWidth: 150,
      },
      {
        field: 'status',
        headerName: 'Status',
        width: 150,
        renderCell: ({ row, value }) => {
          const { error } = row;

          return error && value === PAYMENT_SYSTEM_STATUS.NOT_SYNCED ? (
            <ErrorHint
              content={
                <>
                  {error.code && <Typography>Code: {error.code}</Typography>}
                  <Typography>{error.message}</Typography>
                </>
              }
            >
              {value}
            </ErrorHint>
          ) : (
            value
          );
        },
      },
      {
        field: 'snapshot.id',
        valueGetter: ({ row }) => row.snapshot && row.snapshot.id,
        headerName: 'Snapshot ID',
        width: 150,
      },
      {
        field: 'snapshot.title',
        valueGetter: ({ row }) => row.snapshot && row.snapshot.title,
        headerName: 'Snapshot Title',
        minWidth: 200,
      },
      {
        field: 'button',
        headerName: 'Full name',
        description: 'This column has a value getter and is not sortable.',
        sortable: false,
        renderCell: ({ row }) => {
          const onClick = onResyncClick(row.id);
          return row.name && row.provider && row.casino && row.status === PAYMENT_SYSTEM_STATUS.NOT_SYNCED ? (
            <Button variant="outlined" key={row.name} size="small" onClick={onClick}>
              resync
            </Button>
          ) : null;
        },
        renderHeader: () => (
          <IconButton onClick={onRefreshClick} size="large">
            <Refresh />
          </IconButton>
        ),
      },
    ],
    [onRefreshClick, onResyncClick],
  );

  return (
    <>
      <StyledBox direction="row" justify="space-between">
        <Box direction="row" align="center">
          <StyledLink to="/payment_systems/add">
            <AddButton>Add payment system</AddButton>
          </StyledLink>
        </Box>
      </StyledBox>
      <DataGrid columns={columns} rows={paymentSystems} />
    </>
  );
};

export default PaymentMethods;
