import React from 'react';
import { Switch, Route, useRouteMatch } from 'react-router-dom';
import Cashflows from 'components/organisms/Cashflows';
import CashflowForm from 'components/organisms/CashflowForm';

export default function () {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route exact path={path}>
        <Cashflows />
      </Route>
      <Route exact path={`${path}/add`}>
        <CashflowForm />
      </Route>
    </Switch>
  );
}
