import { requestsFactory } from 'redux-requests-factory';

import axiosInstance from './axios';

const getTags = () => axiosInstance.get('/tags');

export const { loadDataAction: loadTagsAction, responseSelector: tagsSelector } = requestsFactory({
  request: getTags,
  stateRequestKey: 'tags',
  transformResponse: (response) => response || [],
});
