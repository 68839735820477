import { requestsFactory } from 'redux-requests-factory';

import axiosInstance from './axios';

const getPaymentProviders = () => axiosInstance.get('/payment_providers/settings');

export const {
  loadDataAction: loadPaymentProviders,
  responseSelector: paymentProvidersResponseSelector,
} = requestsFactory({
  request: getPaymentProviders,
  stateRequestKey: 'payment-providers',
});
