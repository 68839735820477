import { createSelector } from 'reselect';

export const casinoSnapshotsFormSelector = (state) => state.casinoSnapshotsForm;

export const casinoSnapshotsBodySelector = createSelector([casinoSnapshotsFormSelector], (snapshotForm) => {
  const { snapshotId, casinoIds, casinosAccessData } = snapshotForm;

  const body = {
    snapshot_id: snapshotId,
    casino_ids: casinoIds.map(Number),
    casinos_access_data: casinosAccessData,
  };

  return body;
});
