import React from 'react';
import { Switch, Route, useRouteMatch } from 'react-router-dom';
import PaymentSystems from 'components/organisms/PaymentSystems';
import PaymentSystemForm from 'components/organisms/PaymentSystemForm';

export default function () {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route exact path={path}>
        <PaymentSystems />
      </Route>
      <Route exact path={`${path}/add`}>
        <PaymentSystemForm />
      </Route>
    </Switch>
  );
}
