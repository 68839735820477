import { createStore, applyMiddleware, compose } from 'redux';
import { createEpicMiddleware } from 'redux-observable';
import { createRequestsFactoryMiddleware } from 'redux-requests-factory';

import rootEpic from './epics';
import rootReducer from './reducers';

const epicMiddleware = createEpicMiddleware();
const { middleware: requestsFactoryMiddleware } = createRequestsFactoryMiddleware();
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

export default function configureStore() {
  const store = createStore(rootReducer, composeEnhancers(applyMiddleware(epicMiddleware, requestsFactoryMiddleware)));

  epicMiddleware.run(rootEpic);

  return store;
}
