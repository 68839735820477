import React from 'react';
import { useSelector } from 'react-redux';
import { DataGrid } from '@mui/x-data-grid';
import { isSomethingLoadingSelector } from 'redux-requests-factory';

export default (props) => {
  const isLoading = useSelector(isSomethingLoadingSelector);

  return (
    <DataGrid
      loading={isLoading}
      autoHeight
      disableColumnMenu
      pageSize={15}
      rowsPerPageOptions={[15]}
      pagination
      disableSelectionOnClick
      hideFooterSelectedRowCount
      {...props}
    />
  );
};
