import { combineReducers } from 'redux';
import { requestsReducer, stateRequestsKey } from 'redux-requests-factory';

import { paymentSystemFormReducer } from './payment-system-form';
import { cashflowFormReducer } from './cashflow-form';
import { snapshotFormReducer } from './snapshot-form';
import { casinoSnapshotsFormReducer } from './casino-snapshots-form';

export default combineReducers({
  [stateRequestsKey]: requestsReducer,
  paymentSystemForm: paymentSystemFormReducer,
  cashflowForm: cashflowFormReducer,
  snapshotForm: snapshotFormReducer,
  casinoSnapshotsForm: casinoSnapshotsFormReducer,
});
