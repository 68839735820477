import { requestsFactory } from 'redux-requests-factory';

import axiosInstance from './axios';

const getSnapshots = () => axiosInstance.get('/snapshots');

export const {
  loadDataAction: loadSnapshotsAction,
  forcedLoadDataAction: forceLoadSnapshotsAction,
  responseSelector: snapshotsResponseSelector,
} = requestsFactory({
  request: getSnapshots,
  stateRequestKey: 'snapshots',
  transformResponse: (response) => (response || []).reverse(),
});

const saveSnapshot = (snapshot) => axiosInstance.post('/snapshots', snapshot);

export const {
  doRequestAction: saveSnapshotAction,
  requestFulfilledAction: saveSnapshotFulfilledAction,
  requestRejectedAction: saveSnapshotRejectedAction,
  errorSelector: saveSnapshotErrorSelector,
} = requestsFactory({
  request: saveSnapshot,
  stateRequestKey: 'snapshots__post',
  fulfilledActions: [() => forceLoadSnapshotsAction()],
});
