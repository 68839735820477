import styled from 'styled-components';
import Toolbar from '@mui/material/Toolbar';
import Link from 'components/atoms/Link';

export const StyledToolbar = styled(Toolbar)`
  flex-direction: row;
  justify-content: space-between;
`;

export const NavMenuWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const NavMenuItem = styled(Link)`
  padding: 5px 10px;
`;
