import { INTEGER_MASK, NUMBER_MASK } from 'constants/input';

import React, { memo, useCallback, useMemo, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  accessDataFieldsSelector,
  accessDataSamplesSelector,
  isFeeNeededSelector,
  paymentSystemFormSelector,
} from 'store/payment-system-form/selectors';
import Button from '@mui/material/Button';
import Select from 'components/molecules/Select';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import TextareaAutosize from '@mui/material/TextareaAutosize';
import { setAccessDataAction, setFeeAction } from 'store/payment-system-form';
import { currenciesSelector } from 'api/currencies';
import TabsMUI, { TabPanel } from 'components/molecules/Tabs';
import Box from 'components/atoms/Box';
import MaskedInput from 'components/atoms/MaskedInput';

const COMPONENTS = {
  integer: memo(({ value = '', placeholder = '', ...props }) => (
    <MaskedInput fullWidth mask={INTEGER_MASK} label={props.name} value={value} placeholder={placeholder} {...props} />
  )),
  string: memo(({ value = '', placeholder = '', ...props }) => (
    <TextField fullWidth label={props.name} {...props} value={value} placeholder={placeholder} />
  )),
  text: memo(({ value = '', placeholder = '', ...props }) => (
    <TextField
      fullWidth
      InputProps={{
        fullWidth: true,
        minRows: 3,
        maxRows: 10,
        inputComponent: TextareaAutosize,
      }}
      label={props.name}
      {...props}
      value={value}
      placeholder={placeholder}
    />
  )),
  boolean: memo(({ value, placeholder, onChange, type, ...props }) => {
    const onChangeValue = (event) => onChange(event);

    return (
      <FormControlLabel
        fullWidth
        control={<Checkbox {...props} onChange={onChangeValue} checked={!!value} />}
        label={props.name}
      />
    );
  }),
  array: memo(({ value = [], ...props }) => (
    <FormControl fullWidth>
      <InputLabel>System Name</InputLabel>
      <Select label="System Name" multiple value={value} {...props} />
    </FormControl>
  )),
};

const AccessDataForm = () => {
  const dispatch = useDispatch();
  const { casinos, fee, accessData } = useSelector(paymentSystemFormSelector);
  const currencies = useSelector(currenciesSelector);
  const tabs = useMemo(() => ['default', ...casinos], [casinos]);
  const [activeTab, setActiveTab] = useState(0);
  const dataPerTab = accessData[tabs[activeTab]];
  const defaultData = accessData[tabs[0]];

  const feePerTab = fee[tabs[activeTab]];
  const defaultFee = fee[tabs[0]];
  const isFeeNeeded = useSelector(isFeeNeededSelector);

  const fields = useSelector(accessDataFieldsSelector);
  const samples = useSelector(accessDataSamplesSelector);
  const [areFieldsHidden, setAreFieldsHidden] = useState(true);

  const copyFromSamples = useCallback(() => {
    let updatedAccessData = { ...accessData };
    tabs
      .filter((tab) => tab !== 'default')
      .forEach((tab) => {
        let foundSample = samples.find((item) => item.casino === tab);
        updatedAccessData = { ...updatedAccessData, [tab]: { ...foundSample } };
      });
    dispatch(setAccessDataAction({ ...updatedAccessData }));
  }, [dispatch, samples, tabs, accessData]);

  const onAccessDataChange = useCallback(
    (event, selectEvent) => {
      const isSelect = selectEvent;
      const validEvent = isSelect ? selectEvent : event;
      const key = validEvent.target.name;
      let value = validEvent.target.type === 'checkbox' ? validEvent.target.checked : validEvent.target.value;
      value = isSelect ? event : value;
      const newAccessData = { ...dataPerTab, [key]: value };
      dispatch(setAccessDataAction({ ...accessData, [tabs[activeTab]]: newAccessData }));
    },
    [activeTab, dataPerTab, accessData, tabs, dispatch],
  );

  const onFeeAmountChange = useCallback(
    (event) => {
      const amount = event.target.value;
      const newFee = { ...feePerTab, amount };
      dispatch(setFeeAction({ ...fee, [tabs[activeTab]]: newFee }));
    },
    [activeTab, feePerTab, fee, tabs, dispatch],
  );

  const onFeeCurrencyChange = useCallback(
    (option) => {
      const newFee = { ...feePerTab, currency: option };
      dispatch(setFeeAction({ ...fee, [tabs[activeTab]]: newFee }));
    },
    [activeTab, feePerTab, fee, tabs, dispatch],
  );

  const onHideAccessDataFields = useCallback(
    (event) => {
      setAreFieldsHidden(!areFieldsHidden);
    },
    [areFieldsHidden],
  );

  useEffect(() => {
    if (!tabs[activeTab]) {
      setActiveTab(0);
    }
  }, [tabs, activeTab, setActiveTab]);
  return (
    <>
      <Typography variant="h5">Access Data &amp; Fee</Typography>
      {samples.length > 0 ? (
        <Box>
          <Button variant="outlined" size="small" onClick={copyFromSamples}>
            Copy
          </Button>
        </Box>
      ) : null}

      <Box>
        <FormControlLabel
          control={<Checkbox checked={areFieldsHidden} onChange={onHideAccessDataFields} />}
          label="Hide sensitive data"
        />
      </Box>

      <TabsMUI activeIndex={activeTab} onChange={setActiveTab} variant="scrollable" scrollButtons="auto">
        {tabs.map((name) => (
          <TabPanel key={name} title={name} id={name}>
            {isFeeNeeded && feePerTab ? (
              <>
                <MaskedInput
                  fullWidth
                  mask={NUMBER_MASK}
                  label="Fee"
                  value={feePerTab.amount}
                  onChange={onFeeAmountChange}
                  placeholder={defaultFee.amount}
                />

                <FormControl fullWidth>
                  <InputLabel>Fee (currency)</InputLabel>
                  <Select
                    label="Fee (currency)"
                    options={currencies}
                    value={feePerTab.currency}
                    onChange={onFeeCurrencyChange}
                    placeholder={defaultFee.currency}
                  />
                </FormControl>
              </>
            ) : null}

            {fields.map(({ name, type, items }) => {
              const Component = COMPONENTS[type];
              const isNeededToHideField = type === 'string' && areFieldsHidden && defaultData[name];
              const placeholder = isNeededToHideField ? defaultData[name].replace(/./g, '*') : defaultData[name];
              const options = items ? items.enum : [];
              type = areFieldsHidden ? 'password' : 'text';
              if (options.length) {
                type = 'array';
              }

              return dataPerTab ? (
                <Component
                  key={name}
                  name={name}
                  type={type}
                  onChange={onAccessDataChange}
                  value={dataPerTab[name]}
                  placeholder={placeholder}
                  options={options}
                />
              ) : null;
            })}
            {fields.length === 0 ? <Typography>No access data fields found</Typography> : null}
          </TabPanel>
        ))}
      </TabsMUI>
    </>
  );
};

export default memo(AccessDataForm);
