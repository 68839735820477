import { requestsFactory } from 'redux-requests-factory';

import axiosInstance from './axios';

const getCasinos = () => axiosInstance.get('/casinos');

export const { loadDataAction: loadCasinosAction, responseSelector: casinosResponseSelector } = requestsFactory({
  request: getCasinos,
  stateRequestKey: 'casinos',
  transformResponse: (response) => response || [],
});
