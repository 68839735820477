import { combineEpics, ofType } from 'redux-observable';
import { ignoreElements, mergeMap, mergeMapTo, mapTo, tap } from 'rxjs/operators';
import { NotificationManager } from 'react-notifications';
import { loadPaymentProviders } from 'api/payment-providers';
import { loadTagsAction } from 'api/tags';
import { loadCurrenciesAction } from 'api/currencies';
import { loadCasinosAction } from 'api/casinos';
import { loadAccessDataAction } from 'api/access-data';
import { loadCashflowsAction } from 'api/cashflows';
import { loadEntitiesAction } from 'api/entities';
import { savePaymentSystemFulfilledAction, savePaymentSystemRejectedAction } from 'api/payment-systems';

import { initPaymentSystemFormAction, setProviderAction, resetPaymentSystemFormAction } from './index';

const initPaymentSystemFormEpic = (action$) =>
  action$.pipe(
    ofType(initPaymentSystemFormAction),
    mergeMapTo([
      loadPaymentProviders(),
      loadTagsAction(),
      loadCurrenciesAction(),
      loadCasinosAction(),
      loadCashflowsAction(),
      loadEntitiesAction(),
    ]),
  );

const loadAccessDataSettingsEpic = (action$) =>
  action$.pipe(
    ofType(setProviderAction),
    mergeMap(({ payload }) => [loadAccessDataAction({ provider: payload })]),
  );

const onSavePaymentSystemSuccess = (action$) =>
  action$.pipe(
    ofType(savePaymentSystemFulfilledAction),
    tap(() => NotificationManager.success('Payment system created')),
    mapTo(resetPaymentSystemFormAction()),
  );

const onSavePaymentSystemFail = (action$) =>
  action$.pipe(
    ofType(savePaymentSystemRejectedAction),
    tap(() => NotificationManager.error('Failed to create payment system')),
    ignoreElements(),
  );

export default combineEpics(
  initPaymentSystemFormEpic,
  loadAccessDataSettingsEpic,
  onSavePaymentSystemSuccess,
  onSavePaymentSystemFail,
);
