import { requestsFactory } from 'redux-requests-factory';

import axiosInstance from './axios';

const createCasinoSnapshots = (casinoSnapshots) =>
  axiosInstance.post('/casino_snapshots/batch_create', casinoSnapshots);

export const {
  doRequestAction: createCasinoSnapshotsAction,
  requestFulfilledAction: createCasinoSnapshotsFulfilledAction,
  requestRejectedAction: createCasinoSnapshotsRejectedAction,
  errorSelector: createCasinoSnapshotsErrorSelector,
} = requestsFactory({
  request: createCasinoSnapshots,
  stateRequestKey: 'casino_snapshots__post',
});
