import { createActions, handleActions } from 'redux-actions';
import { noop } from 'rxjs';
import identity from 'ramda/src/identity';
import fromPairs from 'ramda/src/fromPairs';
import uniq from 'ramda/src/uniq';
import pick from 'ramda/src/pick';

const initialLimit = {
  cashout: {
    min: '',
    max: '',
    maxAuto: '',
    fixedCommission: '',
    percentCommission: '',
  },
  deposit: {
    min: '',
    max: '',
    fixedCommission: '',
    percentCommission: '',
  },
};

const initialFee = {
  amount: '',
  currency: null,
};

const DEFAULT_STATE = {
  provider: null,
  system: null,
  currencies: [],
  limits: {
    default: initialLimit,
  },
  tags: [],
  casinos: [],
  cashflows: [],
  accessData: {
    default: {},
  },
  fee: {
    default: initialFee,
  },
};

export const {
  initPaymentSystemFormAction,
  setProviderAction,
  setSystemNameAction,
  setCurrenciesAction,
  setLimitsAction,
  setTagsAction,
  setCasinosAction,
  setCashflowsAction,
  setAccessDataAction,
  setFeeAction,
  resetPaymentSystemFormAction,
} = createActions(
  {
    INIT_PAYMENT_SYSTEM_FORM_ACTION: noop,
    SET_PROVIDER_ACTION: identity,
    SET_SYSTEM_NAME_ACTION: identity,
    SET_CURRENCIES_ACTION: identity,
    SET_LIMITS_ACTION: identity,
    SET_TAGS_ACTION: identity,
    SET_CASINOS_ACTION: identity,
    SET_CASHFLOWS_ACTION: identity,
    SET_ACCESS_DATA_ACTION: identity,
    SET_FEE_ACTION: identity,
    RESET_PAYMENT_SYSTEM_FORM_ACTION: noop,
  },
  {
    prefix: 'PAYMENT_SYSTEM_FORM',
  },
);

export const paymentSystemFormReducer = handleActions(
  {
    [setProviderAction]: (state, { payload }) => ({ ...state, provider: payload, system: null }),
    [setSystemNameAction]: (state, { payload }) => ({ ...state, system: payload }),
    [setCurrenciesAction]: (state, { payload }) => {
      const currencies = uniq(payload);
      const baseLimits = fromPairs(currencies.map((currency) => [currency, initialLimit]));
      return { ...state, currencies, limits: { ...baseLimits, ...state.limits } };
    },
    [setLimitsAction]: (state, { payload }) => ({ ...state, limits: payload }),
    [setTagsAction]: (state, { payload }) => ({ ...state, tags: payload }),
    [setCasinosAction]: (state, { payload }) => {
      const baseAccessData = fromPairs(payload.map((casino) => [casino, {}]));
      const baseFee = fromPairs(payload.map((casino) => [casino, initialFee]));
      const modifiedAccessData = pick([...payload, 'default'], state.accessData);
      const modifiedFee = pick([...payload, 'default'], state.fee);
      return {
        ...state,
        casinos: payload,
        accessData: { ...baseAccessData, ...modifiedAccessData },
        fee: { ...baseFee, ...modifiedFee },
      };
    },
    [setCashflowsAction]: (state, { payload }) => ({ ...state, cashflows: payload }),
    [setAccessDataAction]: (state, { payload }) => ({ ...state, accessData: payload }),
    [setFeeAction]: (state, { payload }) => ({ ...state, fee: payload }),
    [resetPaymentSystemFormAction]: () => ({ ...DEFAULT_STATE }),
  },
  DEFAULT_STATE,
);
