import { requestsFactory } from 'redux-requests-factory';
import uniq from 'ramda/src/uniq';

import axiosInstance from './axios';

const getCurrencies = () => axiosInstance.get('/currencies');

export const { loadDataAction: loadCurrenciesAction, responseSelector: currenciesSelector } = requestsFactory({
  request: getCurrencies,
  stateRequestKey: 'currencies',
  transformResponse: (response) => uniq(response || []),
});
