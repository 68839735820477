import { NUMBER_MASK } from 'constants/input';

import React, { useCallback, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Typography from '@mui/material/Typography';
import InputAdornment from '@mui/material/InputAdornment';
import Grid from '@mui/material/Grid';
import { setLimitsAction } from 'store/payment-system-form';
import { paymentSystemFormSelector } from 'store/payment-system-form/selectors';
import Tabs, { TabPanel } from 'components/molecules/Tabs';
import MaskedInput from 'components/atoms/MaskedInput';

const LimitsForm = () => {
  const dispatch = useDispatch();
  const { currencies, limits } = useSelector(paymentSystemFormSelector);

  const tabs = useMemo(() => ['default', ...currencies], [currencies]);
  const [activeTab, setActiveTab] = useState(0);

  const defaultLimits = limits[tabs[0]];
  const limitsPerTab = limits[tabs[activeTab]];

  const onLimitsUpdate = useCallback(
    (event) => {
      const operation = event.target.dataset.operation;
      const key = event.target.name;
      const value = event.target.value;
      const newLimit = { ...limitsPerTab, [operation]: { ...limitsPerTab[operation], [key]: value } };
      dispatch(setLimitsAction({ ...limits, [tabs[activeTab]]: newLimit }));
    },
    [activeTab, limitsPerTab, limits, tabs, dispatch],
  );

  return (
    <>
      <Typography variant="h5">Limits</Typography>
      <Tabs justify="start" activeIndex={activeTab} onChange={setActiveTab} variant="scrollable" scrollButtons="auto">
        {tabs.map((name) => (
          <TabPanel key={name} id={name} title={name}>
            <Grid container spacing={2}>
              <Grid item md={6}>
                <Typography variant="h6">Deposit</Typography>

                <MaskedInput
                  fullWidth
                  mask={NUMBER_MASK}
                  name="min"
                  label="min"
                  inputProps={{
                    'data-operation': 'deposit',
                  }}
                  value={limitsPerTab.deposit.min}
                  onChange={onLimitsUpdate}
                  placeholder={defaultLimits.deposit.min}
                />

                <MaskedInput
                  fullWidth
                  mask={NUMBER_MASK}
                  label="max"
                  name="max"
                  inputProps={{
                    'data-operation': 'deposit',
                  }}
                  value={limitsPerTab.deposit.max}
                  onChange={onLimitsUpdate}
                  placeholder={defaultLimits.deposit.max}
                />

                <MaskedInput
                  fullWidth
                  mask={NUMBER_MASK}
                  label="commission (fixed)"
                  name="fixedCommission"
                  value={limitsPerTab.deposit.fixedCommission}
                  onChange={onLimitsUpdate}
                  placeholder={defaultLimits.deposit.fixedCommission}
                  inputProps={{
                    'data-operation': 'deposit',
                  }}
                />

                <MaskedInput
                  fullWidth
                  mask={NUMBER_MASK}
                  InputProps={{
                    endAdornment: <InputAdornment position="end">%</InputAdornment>,
                  }}
                  label="commission (percent)"
                  name="percentCommission"
                  value={limitsPerTab.deposit.percentCommission}
                  onChange={onLimitsUpdate}
                  placeholder={defaultLimits.deposit.percentCommission}
                  inputProps={{
                    'data-operation': 'deposit',
                  }}
                />
              </Grid>
              <Grid item md={6}>
                <Typography variant="h6">Cashout</Typography>
                <MaskedInput
                  fullWidth
                  mask={NUMBER_MASK}
                  name="min"
                  label="min"
                  inputProps={{
                    'data-operation': 'cashout',
                  }}
                  value={limitsPerTab.cashout.min}
                  onChange={onLimitsUpdate}
                  placeholder={defaultLimits.cashout.min}
                />
                <MaskedInput
                  fullWidth
                  mask={NUMBER_MASK}
                  name="max"
                  label="max"
                  inputProps={{
                    'data-operation': 'cashout',
                  }}
                  value={limitsPerTab.cashout.max}
                  onChange={onLimitsUpdate}
                  placeholder={defaultLimits.cashout.max}
                />
                <MaskedInput
                  fullWidth
                  mask={NUMBER_MASK}
                  name="maxAuto"
                  label="max (auto)"
                  inputProps={{
                    'data-operation': 'cashout',
                  }}
                  value={limitsPerTab.cashout.maxAuto}
                  onChange={onLimitsUpdate}
                  placeholder={defaultLimits.cashout.maxAuto}
                />
                <MaskedInput
                  fullWidth
                  mask={NUMBER_MASK}
                  name="fixedCommission"
                  label="commission (fixed)"
                  inputProps={{
                    'data-operation': 'cashout',
                  }}
                  value={limitsPerTab.cashout.fixedCommission}
                  onChange={onLimitsUpdate}
                  placeholder={defaultLimits.cashout.fixedCommission}
                />
                <MaskedInput
                  fullWidth
                  mask={NUMBER_MASK}
                  InputProps={{
                    endAdornment: <InputAdornment position="end">%</InputAdornment>,
                  }}
                  name="percentCommission"
                  label="commission (percent)"
                  inputProps={{
                    'data-operation': 'cashout',
                  }}
                  value={limitsPerTab.cashout.percentCommission}
                  onChange={onLimitsUpdate}
                  placeholder={defaultLimits.cashout.percentCommission}
                />
              </Grid>
            </Grid>
          </TabPanel>
        ))}
      </Tabs>
    </>
  );
};

export default LimitsForm;
