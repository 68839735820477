import { ofType, combineEpics } from 'redux-observable';
import { ignoreElements, tap, map } from 'rxjs/operators';
import { NotificationManager } from 'react-notifications';
import {
  resyncPaymentSystemFulfilledAction,
  resyncPaymentSystemRejectedAction,
  paymentsSystemsResponseSelector,
  setPaymentSystemResponse,
} from 'api/payment-systems';

const onResyncPaymentSystemSuccess = (action$, state$) =>
  action$.pipe(
    ofType(resyncPaymentSystemFulfilledAction),
    tap(() => {
      NotificationManager.success('Resync is requested');
    }),
    map(({ payload: { response } }) => {
      const systems = paymentsSystemsResponseSelector(state$.value);

      return setPaymentSystemResponse({
        response: systems.map((system) => {
          if (system.id === response.id) {
            return response;
          }

          return system;
        }),
      });
    }),
  );

const onResyncPaymentSystemFail = (action$) =>
  action$.pipe(
    ofType(resyncPaymentSystemRejectedAction),
    tap(() => NotificationManager.error('Failed to request for the resync')),
    ignoreElements(),
  );

export default combineEpics(onResyncPaymentSystemSuccess, onResyncPaymentSystemFail);
