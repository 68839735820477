import { createSelector } from 'reselect';
import map from 'ramda/src/map';
import { paymentsSystemsResponseSelector } from 'api/payment-systems';

export const paymentSystemsSelector = createSelector(
  paymentsSystemsResponseSelector,
  map((paymentSystem) => {
    const { snapshots = [], error = {} } = paymentSystem;
    const lastSnapshot = snapshots.reduce((acc, snapshot) => {
      if (!acc || acc.id < snapshot.id) {
        return snapshot;
      }

      return acc;
    }, null);

    return {
      id: paymentSystem.id,
      error: error.message ? error : null,
      name: paymentSystem.name,
      status: paymentSystem.status,
      casino: paymentSystem.casino.codename,
      provider: paymentSystem.payment_provider.name,
      snapshot: lastSnapshot,
    };
  }),
);
