import { requestsFactory } from 'redux-requests-factory';

import axiosInstance from './axios';

const getAccessData = ({ provider }) => axiosInstance.get(`/access_data/${provider}`);

export const {
  loadDataAction: loadAccessDataAction,
  requestFulfilledAction: accessDataFulfilledAction,
  responseSelector: accessDataResponseSelector,
} = requestsFactory({
  request: getAccessData,
  stateRequestKey: 'access-data',
  serializeRequestParameters: ({ provider }) => `${provider}`,
  transformResponse: (response) => response || {},
});
