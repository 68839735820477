import React, { useCallback, useEffect } from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import FormHelperText from '@mui/material/FormHelperText';
import { useDispatch, useSelector } from 'react-redux';
import { casinosResponseSelector } from 'api/casinos';
import {
  initPaymentSystemFormAction,
  setProviderAction,
  setSystemNameAction,
  setCurrenciesAction,
  setTagsAction,
  setCasinosAction,
  setCashflowsAction,
} from 'store/payment-system-form';
import {
  paymentProvidersSelector,
  paymentSystemFormSelector,
  systemNamesSelector,
  paymentSystemBodySelector,
} from 'store/payment-system-form/selectors';
import { cashflowsResponseSelector as cashflowsSelector } from 'api/cashflows';
import { tagsSelector } from 'api/tags';
import { currenciesSelector } from 'api/currencies';
import { savePaymentSystemAction } from 'api/payment-systems';
import Select from 'components/molecules/Select';
import prop from 'ramda/src/prop';
import { useForm } from 'react-hook-form';

import LimitsForm from './sub-components/LimitsForm';
import AccessDataForm from './sub-components/AccessDataForm';
import { PaymentSystemControl } from './PaymentSystem.styles';

const SYSTEM_NAME_EXCLUSIONS = new Set(['card-acquirer']);

const groupCasinosOptions = (options) =>
  options.reduce((acc, option) => {
    const entityName = option.origin.entity_name || 'other';
    acc[entityName] = acc[entityName] || [];
    acc[entityName].push(option);

    return acc;
  }, {});

const PaymentSystem = () => {
  const dispatch = useDispatch();
  const {
    handleSubmit,
    register,
    setValue,
    resetField,
    formState: { errors },
  } = useForm({ mode: 'all' });

  useEffect(() => {
    dispatch(initPaymentSystemFormAction());
  }, [dispatch]);

  const paymentSystemBody = useSelector(paymentSystemBodySelector);
  const onSaveClick = useCallback(() => handleSubmit(() => dispatch(savePaymentSystemAction(paymentSystemBody)))(), [
    paymentSystemBody,
    dispatch,
    handleSubmit,
  ]);

  const onChangeTags = (value) => dispatch(setTagsAction(value));
  const tagsOptions = useSelector(tagsSelector);

  const onChangeCurrencies = (value) => dispatch(setCurrenciesAction(value));
  const currenciesOptions = useSelector(currenciesSelector);

  const onChangeCashflows = (value) => dispatch(setCashflowsAction(value));
  const cashflowsOptions = useSelector(cashflowsSelector);

  const onChangeSystemName = (value) => {
    setValue('system-name', value);
    dispatch(setSystemNameAction(value));
  };

  const onChangeSystemNameText = (event) => {
    onChangeSystemName(event.target.value);
    onChangeHandler(event);
  };
  const systemNameOptions = useSelector(systemNamesSelector);

  const onChangeProvider = (value) => dispatch(setProviderAction(value));
  const providerOptions = useSelector(paymentProvidersSelector);

  const casinosOptions = useSelector(casinosResponseSelector);
  const onChangeCasinos = useCallback((value) => {
    dispatch(setCasinosAction(value));
  }, []);

  const { provider, system, currencies, tags, casinos, cashflows } = useSelector(paymentSystemFormSelector);

  useEffect(() => {
    resetField('system-name');
  }, [provider, resetField]);

  const { ref, onChange: onChangeHandler, ...rest } = register('system-name', {
    required: 'Required',
    validate:
      provider === 'finteqhub_seamless'
        ? (value) => {
            const isValid = SYSTEM_NAME_EXCLUSIONS.has(value) || /^([a-z]|-|_)+~([a-z]|-|_)+[a-z]$/g.test(value);
            return isValid || 'Incorrect value for system name';
          }
        : undefined,
  });

  return (
    <>
      <Typography variant="h4" align="center">
        New Configuration
      </Typography>

      <PaymentSystemControl>
        <Button variant="outlined" onClick={onSaveClick}>
          Save
        </Button>
      </PaymentSystemControl>
      <Grid container spacing={5}>
        <Grid item md={3}>
          <Typography variant="h5">Common</Typography>

          <FormControl fullWidth>
            <InputLabel htmlFor="provider">Payment Provider</InputLabel>
            <Select
              label="Payment Provider"
              id="provider"
              options={providerOptions}
              value={provider}
              onChange={onChangeProvider}
            />
          </FormControl>

          <FormControl fullWidth error={!!errors['system-name']}>
            <InputLabel htmlFor="system-name">System Name</InputLabel>
            {provider === 'finteqhub_seamless' ? (
              <TextField
                error={!!errors['system-name']}
                inputRef={ref}
                {...rest}
                value={system}
                onChange={onChangeSystemNameText}
              />
            ) : (
              <>
                <Select
                  helperText={errors['system-name'] ? errors['system-name'].message : ''}
                  label="System Name"
                  id="system-name"
                  options={systemNameOptions}
                  value={system}
                  onChange={onChangeSystemName}
                  emptyState={!provider ? 'Choose provider first' : 'No available options'}
                />
              </>
            )}
            {errors['system-name'] ? <FormHelperText>{errors['system-name'].message}</FormHelperText> : null}
          </FormControl>

          <FormControl fullWidth>
            <InputLabel htmlFor="tags">Tags</InputLabel>
            <Select label="Tags" multiple id="tags" options={tagsOptions} value={tags} onChange={onChangeTags} />
          </FormControl>

          <FormControl fullWidth>
            <InputLabel htmlFor="currencies">Currencies</InputLabel>
            <Select
              label="Currencies"
              multiple
              id="currencies"
              options={currenciesOptions}
              value={currencies}
              onChange={onChangeCurrencies}
            />
          </FormControl>

          <FormControl fullWidth>
            <InputLabel htmlFor="casinos">Casinos</InputLabel>
            <Select
              label="Casinos"
              multiple
              id="casinos"
              valueGetter={prop('codename')}
              labelGetter={prop('codename')}
              groupWith={groupCasinosOptions}
              options={casinosOptions}
              value={casinos}
              onChange={onChangeCasinos}
            />
          </FormControl>

          <FormControl fullWidth>
            <InputLabel htmlFor="cashflows">Cashflows</InputLabel>
            <Select
              label="Cashflows"
              multiple
              id="cashflows"
              multipleLabelGetter={prop('name')}
              labelGetter={prop('name')}
              options={cashflowsOptions}
              value={cashflows}
              onChange={onChangeCashflows}
            />
          </FormControl>
        </Grid>
        <Grid item md={5}>
          <LimitsForm />
        </Grid>
        <Grid item md={4}>
          <AccessDataForm />
        </Grid>
      </Grid>
    </>
  );
};

export default PaymentSystem;
