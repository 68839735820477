import React, { memo, useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { casinosResponseSelector } from 'api/casinos';
import {
  setCasinoSnapshotsCasinosAction,
  setCasinoSnapshotsIdAction,
  setCasinoSnapshotsAccessDataAction,
  initCasinoSnapshotsFormAction,
} from 'store/casino-snapshots-form';
import { casinoSnapshotsBodySelector, casinoSnapshotsFormSelector } from 'store/casino-snapshots-form/selectors';
import { createCasinoSnapshotsAction } from 'api/casino-snapshots';
import { casinosOptionsSelector } from 'store/payment-system-form/selectors';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import Button from '@mui/material/Button';
import TextareaAutosize from '@mui/material/TextareaAutosize';
import CheckboxGroup from 'components/molecules/CheckboxGroup/CheckboxGroup';

const CasinoSnapshotsForm = () => {
  useEffect(() => {
    dispatch(initCasinoSnapshotsFormAction());
  }, []);
  const dispatch = useDispatch();
  const casinos = useSelector(casinosOptionsSelector) || [];

  const { snapshotId, casinoIds, casinosAccessData } = useSelector(casinoSnapshotsFormSelector);
  const createCasinoSnapshotsRequestBody = useSelector(casinoSnapshotsBodySelector);

  const onChangeSnapshotId = useCallback((event) => dispatch(setCasinoSnapshotsIdAction(event.target.value)), []);
  const onChangeCasinoSnapshotsCasinos = useCallback((value) => dispatch(setCasinoSnapshotsCasinosAction(value)), []);
  const onChangeCasinoSnapshotsAccessData = useCallback(
    (event) => dispatch(setCasinoSnapshotsAccessDataAction(event.target.value)),
    [],
  );
  const createCasinoSnapshots = useCallback(
    () => dispatch(createCasinoSnapshotsAction(createCasinoSnapshotsRequestBody)),
    [createCasinoSnapshotsRequestBody],
  );

  return (
    <>
      <Typography variant="h6">Cast snapshot to selected casinos</Typography>
      <div>
        <TextField label="Snapshot Id" fullWidth value={snapshotId} onChange={onChangeSnapshotId} />
        <FormControl>
          <FormLabel>Casinos</FormLabel>
          <CheckboxGroup
            row
            options={casinos}
            onChange={onChangeCasinoSnapshotsCasinos}
            labelProperty="codename"
            valueProperty="id"
          />
        </FormControl>
        <TextField
          fullWidth
          label="Casinos Access Data (JSON)"
          value={casinosAccessData}
          onChange={onChangeCasinoSnapshotsAccessData}
          inputProps={{
            minRows: 5,
            maxRows: 20,
          }}
          InputProps={{
            inputComponent: TextareaAutosize,
          }}
        />

        <div>
          <Button variant="contained" onClick={createCasinoSnapshots}>
            Apply
          </Button>
        </div>
      </div>
    </>
  );
};

export default memo(CasinoSnapshotsForm);
