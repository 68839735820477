import reduce from 'ramda/src/reduce';
import identity from 'ramda/src/identity';

export const createMapByKey = (key, formatter = identity) => {
  return (items) => {
    return reduce(
      (acc, item) => {
        const value = formatter(item[key]);

        acc[value] = item;

        return acc;
      },
      {},
      items || [],
    );
  };
};
