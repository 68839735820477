import { requestsFactory } from 'redux-requests-factory';

import axiosInstance from './axios';

const getCashflows = () => axiosInstance.get('/cashflows');

export const {
  loadDataAction: loadCashflowsAction,
  forcedLoadDataAction: forceLoadCashflowsAction,
  responseSelector: cashflowsResponseSelector,
} = requestsFactory({
  request: getCashflows,
  stateRequestKey: 'cashflows',
  transformResponse: (response) =>
    (response || [])
      .map((cashflow) => ({
        id: cashflow.id,
        name: cashflow.name,
        currency: cashflow.currency,
        acceptableCurrencies: cashflow.acceptable_currencies,
      }))
      .reverse(),
});

const saveCashflow = (cashflow) => axiosInstance.post('/cashflows', cashflow);

export const {
  doRequestAction: saveCashflowAction,
  requestFulfilledAction: saveCashflowFulfilledAction,
  requestRejectedAction: saveCashflowRejectedAction,
  errorSelector: saveCashflowErrorSelector,
} = requestsFactory({
  request: saveCashflow,
  stateRequestKey: 'cashflows__post',
  fulfilledActions: [() => forceLoadCashflowsAction()],
});
