import { combineEpics } from 'redux-observable';

import paymentSystemFormEpics from './payment-system-form/epics';
import homePageEpics from './home-page/epics';
import cashflowFormEpics from './cashflow-form/epics';
import snapshotFormEpics from './snapshot-form/epics';
import casinoSnapshotsFormEpics from './casino-snapshots-form/epics';

export default combineEpics(
  paymentSystemFormEpics,
  cashflowFormEpics,
  snapshotFormEpics,
  casinoSnapshotsFormEpics,
  homePageEpics,
);
