import React from 'react';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import Notifications from '@mui/icons-material/Notifications';
import { useTranslation } from 'react-i18next';
import Link from 'components/atoms/Link';
import AppBar from '@mui/material/AppBar';

import { StyledToolbar, NavMenuWrapper, NavMenuItem } from './Header.styles';

const Header = () => {
  const { t } = useTranslation();

  return (
    <AppBar position="static">
      <StyledToolbar>
        <Link to="/">
          <Typography variant="h6">{t('header.title')}</Typography>
        </Link>
        <Typography>
          <NavMenuWrapper>
            <NavMenuItem to="/payment_systems">Payment Systems</NavMenuItem>
            <NavMenuItem to="/cashflows">Cashflows</NavMenuItem>
            <NavMenuItem to="/snapshots">Snapshots</NavMenuItem>
          </NavMenuWrapper>
        </Typography>
        <IconButton size="large">
          <Notifications />
        </IconButton>
      </StyledToolbar>
    </AppBar>
  );
};

export default Header;
