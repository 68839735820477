import { createSelector } from 'reselect';
import compose from 'ramda/src/compose';
import pathOr from 'ramda/src/pathOr';
import keys from 'ramda/src/keys';
import map from 'ramda/src/map';
import prop from 'ramda/src/prop';
import sortBy from 'ramda/src/sortBy';
import identity from 'ramda/src/identity';
import toPairs from 'ramda/src/toPairs';
import isNil from 'ramda/src/isNil';
import { paymentProvidersResponseSelector } from 'api/payment-providers';
import { cashflowsResponseSelector } from 'api/cashflows';
import { casinosResponseSelector } from 'api/casinos';
import { accessDataResponseSelector } from 'api/access-data';
import { createMapByKey } from 'utils';

export const paymentSystemFormSelector = (state) => state.paymentSystemForm;

export const paymentProvidersSelector = createSelector(paymentProvidersResponseSelector, keys);
export const systemNamesSelector = createSelector(
  [paymentProvidersResponseSelector, paymentSystemFormSelector],
  (providersResponse, { provider }) =>
    compose(sortBy(identity), map(prop('name')), pathOr([], [provider, 'payment_systems']))(providersResponse),
);
export const casinosSelector = createSelector(casinosResponseSelector, map(prop('codename')));
export const casinosOptionsSelector = createSelector(
  casinosResponseSelector,
  map((casino) => {
    casino.id = String(casino.id);
    return casino;
  }),
);
export const casinoByNameSelector = createSelector(casinosResponseSelector, createMapByKey('codename'));
export const casinoByIdSelector = createSelector(casinosResponseSelector, createMapByKey('id'));

const providerSelector = createSelector(paymentSystemFormSelector, prop('provider'));
export const accessDataFieldsSelector = createSelector(
  [accessDataResponseSelector, providerSelector],
  (accessDataResponse, provider) => {
    const accessData = accessDataResponse({ provider });
    // TODO: remove when backend is ready https://youtrack.softswiss.net/issue/CASINO-15503
    /*
     if (accessData.fields) {
      accessData.fields = accessData.fields.map((field) => {
        if (['merchant_private_key', 'trustly_public_key'].includes(field.name)) {
          field.type = 'text';
        }
        return field;
      });
    }*/
    return pathOr([], ['fields'], accessData);
  },
);
export const accessDataSamplesSelector = createSelector(
  [accessDataResponseSelector, providerSelector, casinoByIdSelector],
  (accessDataResponse, provider, casinoById) =>
    compose(
      map(({ ...attributes }) => ({
        ...attributes,
        casino: pathOr(null, [attributes.casino_id, 'codename'], casinoById),
      })),
      pathOr([], ['existing']),
    )(accessDataResponse({ provider })),
);

const NEEDED_FEE_PROVIDERS = ['devcode', 'finteqhub', 'finteqhub_seamless'];
export const isFeeNeededSelector = createSelector([providerSelector], (provider) => {
  return NEEDED_FEE_PROVIDERS.includes(provider);
});

const withBackup = (value, defaultValue) => (isNil(value) || value === '' ? defaultValue : value);
export const paymentSystemBodySelector = createSelector(
  [paymentSystemFormSelector, casinoByNameSelector, accessDataFieldsSelector],
  (paymentSystemForm, casinoByName, accessDataFields) => {
    const { provider, system, limits, tags, accessData, fee, cashflows } = paymentSystemForm;
    const { default: defaultLimit, ...limitsByCurrency } = limits;
    const { default: defaultAccessData, ...accessDataByCasino } = accessData;
    const { default: defaultFee, ...feeByCasino } = fee;

    const body = {
      payment_provider_name: provider,
      payment_system_name: system,
      tags,
      limits: toPairs(limitsByCurrency).map(([currency, limit]) => ({
        currency,
        min_deposit: withBackup(limit.deposit.min, defaultLimit.deposit.min),
        max_deposit: withBackup(limit.deposit.max, defaultLimit.deposit.max),
        min_cashout: withBackup(limit.cashout.min, defaultLimit.cashout.min),
        max_auto_cashout: withBackup(limit.cashout.maxAuto, defaultLimit.cashout.maxAuto),
        max_cashout: withBackup(limit.cashout.max, defaultLimit.cashout.max),
        cashout_commission_percent: withBackup(limit.cashout.percentCommission, defaultLimit.cashout.percentCommission),
        cashout_commission_fixed: withBackup(limit.cashout.fixedCommission, defaultLimit.cashout.fixedCommission),
        deposit_commission_percent: withBackup(limit.deposit.percentCommission, defaultLimit.deposit.percentCommission),
        deposit_commission_fixed: withBackup(limit.deposit.fixedCommission, defaultLimit.deposit.fixedCommission),
      })),
      casino_settings: toPairs(accessDataByCasino).map(([casinoName, accessData]) => {
        const formattedAccessData = accessDataFields.reduce((acc, { name, type }) => {
          const value = withBackup(accessData[name], defaultAccessData[name]);
          acc[name] = type === 'integer' ? Number.parseInt(value, 10) : value;

          return acc;
        }, {});
        const paymentSystemFeeAmount = withBackup(feeByCasino[casinoName].amount, defaultFee.amount);
        const paymentSystemFeeCurrency = withBackup(feeByCasino[casinoName].currency, defaultFee.currency);

        return {
          casino_id: casinoByName[casinoName].id,
          access_data: formattedAccessData,
          payment_system_fee: paymentSystemFeeAmount,
          payment_system_fee_currency: paymentSystemFeeCurrency,
        };
      }),
      cashflow_ids: cashflows.map(prop('id')),
    };

    return body;
  },
);
