import { requestsFactory } from 'redux-requests-factory';

import axiosInstance from './axios';

const getEntities = () => axiosInstance.get('/entities');

export const {
  loadDataAction: loadEntitiesAction,
  responseSelector: entitiesResponseSelector,
} = requestsFactory({
  request: getEntities,
  stateRequestKey: 'entities',
  transformResponse: (response) => response || []
});
