import React, { useCallback } from 'react';
import TextField from '@mui/material/TextField';
import MaskedInput from 'react-text-mask';

export default function ({ mask, InputProps, ...inputProps }) {
  const TextMaskCustom = useCallback(
    (props) => {
      return <MaskedInput {...props} mask={mask} />;
    },
    [mask],
  );

  return (
    <TextField
      {...inputProps}
      InputProps={{
        ...InputProps,
        inputComponent: TextMaskCustom,
      }}
    />
  );
}
